import $ from 'jquery';
import objectFitImages from 'object-fit-images';
import initFaq from './components/faq';
import initToTop from './components/to-top';
import initScrollTable from './components/scroll-table';
import initHeader from './components/header';

import initCategoryMenu from './components/category-menu';

objectFitImages();

initHeader();

if ($('.to-top').length) {
  initToTop();
}
if ($('.faq').length) {
  initFaq();
}
if ($('.table--scroll').length) {
  initScrollTable();
}
if ($('.category-menu').length) {
  initCategoryMenu();
}

if ($('.with-image').length) {
  const $withImage = $('.with-image');
  $withImage.each(function () {
    const $this = $(this);
    if ($this.hasClass('with-image--bg') && !$this.find('.caption').length) {
      $this.addClass('with-image--has-not-caption');
    }
  });
}

if ($('.wide-image').length) {
  const $wideImage = $('.wide-image');
  $wideImage.each(function () {
    const $this = $(this);
    if ($this.hasClass('wide-image--bg') && !$this.find('.caption').length) {
      $this.addClass('wide-image--has-not-caption');
    }
  });
}
if ($('.wide-video').length) {
  const $wideVideo = $('.wide-video');
  $wideVideo.each(function () {
    const $this = $(this);
    if ($this.hasClass('wide-video--bg') && !$this.find('.caption').length) {
      $this.addClass('wide-video--has-not-caption');
    }
  });
}

if ($('.step-list').length) {
  const $stepList = $('.step-list');
  $stepList.each(function () {
    const $this = $(this);
    if ($this.hasClass('step-list--bg') && !$this.find('.caption').length) {
      $this.addClass('step-list--has-not-caption');
    }
  });
}
